import React, { useState, useEffect, Suspense } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useStoreActions, useStoreState } from '../../hooks/easyPeasyHooks';
import InternalError from '../internalError/InternalError';
import { useTrack, usePage, useIdentify } from '../../hooks/segment';
import { AppMode } from '../../stores/order.store';
import PageLoader from '../../components/pageLoader/PageLoader';
import FormWrapper from '../../components/formWrapper/FormWrapper';
import LoanTermsTooltip from '../../components/loans-terms-tooltip/loans-terms-tooltip';
import LeaseTermsTooltip from '../../components/lease-terms-tooltip/lease-terms-tooltip';
import TextField from '../../components/inputs/TextField';
import { colors } from '../../components/baseStyle/Variables';
import Button from '../../components/button/Button';
import { ChannelTypes, FormTypes } from '../../gql/graphql';
import { Order } from '../../gql/graphql';
import { AppConfig } from '../../app.config';

export interface OTPRequestProps {
  messages: { approvedText?: string };
  onNext?: any;
}

const isVirtualCardAppFinished = (order: Order) => {
  switch (order?.application?.lease?.status) {
    case 'readyForFunding':
    case 'approvedForFunding':
    case 'funded':
    case 'docsSigned':
      return true;
    default:
      return false;
  }
};

const OTPRequest: React.FC<OTPRequestProps> = ({ messages, onNext }) => {
  const isVirtualCard = useStoreState((state) => state.order.order?.dealer.isVirtualCard);
  const isBrickAndMortar =
    useStoreState((state) => state.order?.order?.dealer?.channelType) === ChannelTypes.BrickAndMortar;
  const financingType = useStoreState((state) => state.order.order?.dealer.financingType);
  const appMode = useStoreState((state) => state.order.appMode);
  const sendToken = useStoreActions((actions) => actions.auth.sendVerificationToken);
  const { setSendVerificationTokenResponse } = useStoreActions((actions) => actions.auth);
  const setCellPhone = useStoreActions((actions) => actions.applicationForm.setCellPhone);
  const appCellPhone = useStoreState((state) => state.applicationForm.cellPhone);
  const { sendVerificationTokenResponse } = useStoreState((state) => state.auth);
  const orderCellPhone = useStoreState((state) => state.order.order?.details?.customer?.cellPhone);
  const { friendlyName, isLongForm } = useStoreState((state) => state.order);
  const formType = useStoreState((state) => state.order.order?.dealer?.formType);
  const order = useStoreState((state) => state.order.order);
  const identify = useIdentify();
  const track = useTrack();
  const page = usePage();

  // used to prevent screen redirection when coming back to page
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cellPhoneErrorMsg = 'Please enter a valid 10 digit mobile number';
  const validationSchema = () => {
    //clear the api error
    setSendVerificationTokenResponse({ __typename: 'SendVerificationTokenResponse', status: '', sendTokenErrors: [] });
    return Yup.object().shape({
      cellPhone: Yup.string().required(cellPhoneErrorMsg).min(10, cellPhoneErrorMsg).max(10, cellPhoneErrorMsg),
    });
  };

  //used to determine error message to display to user
  const typeNameErrorMapper = () => {
    let errMessage: string | undefined;
    // if user navigates back screens and come back, we don't want error to display.
    if (!shouldRedirect) {
      return;
    }
    if (sendVerificationTokenResponse?.sendTokenErrors.some((error) => error.__typename === 'SMSNotSent')) {
      errMessage = `Something went wrong. Please try submitting your number again.`;
    } else if (sendVerificationTokenResponse?.sendTokenErrors.some((error) => error.__typename === 'MaxAttempts')) {
      errMessage = `Too many incorrect attempts. Don't worry—you can try again in 10 minutes.`;
    }
    if (errMessage) {
      setIsLoading(false);
    }
    return errMessage;
  };

  const handleSubmit = async (e: any, actions: any) => {
    setIsLoading(true);
    track('Mobile Number Entered');
    identify({}); // no relevant data we can include here yet
    sendToken(e);
    setCellPhone(e.cellPhone);
    setShouldRedirect(true);
  };

  useEffect(() => {
    page('OTP Request');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shouldRedirect && sendVerificationTokenResponse?.status === 'pending') {
      onNext();
      // history.push(`/check-token?orderId=${orderId}`);
    }
  }, [sendVerificationTokenResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  //FIXME: we need to loop through these errors and maybe put this in a react hook (onAESError?) for sharing this
  // function across components/pages, but for now i will just pick the first in the array of errors and proceed.
  if (sendVerificationTokenResponse && sendVerificationTokenResponse?.sendTokenErrors.length > 0) {
    switch (sendVerificationTokenResponse.sendTokenErrors[0]?.__typename) {
      case 'FatalError':
        track('Internal Error Occurred - OTP Request');
        return <InternalError />;
      case 'SMSNotSent':
      case 'MaxAttempts':
        break;
      default:
    }
  }

  const Title = () => {
    if (order?.dealer?.isVirtualCard && isVirtualCardAppFinished(order)) {
      return <h1>Welcome Back!</h1>;
    } else {
      return <h1>Pay over time at {friendlyName}</h1>;
    }
  };
  const getSkipUnifiedUrl = () => {
    var transferUrl = `${AppConfig.dealerPortalUrl}/ApplicationForm/IntroScreen?publicStoreId=${order?.dealer?.publicDealerId}&skipUnifiedApplication=true`;
    // propagate campaign medium and campaign source
    const cm = new URLSearchParams(document.location.search.substring(1)).get('cm');
    if (cm) transferUrl += `&cm=${cm}`;
    const cs = new URLSearchParams(document.location.search.substring(1)).get('cs');
    if (cs) transferUrl += `&cs=${cs}`;
    return transferUrl;
  };

  const Subtitle = () => {
    if (order?.dealer?.isVirtualCard && isVirtualCardAppFinished(order)) {
      return <h6>Enter your mobile number to get a verification code and view your virtual card.</h6>;
    }
    return (
      <h6>
        Koalafi's{' '}
        {appMode === AppMode.loan && (
          <>
            <LoanTermsTooltip styling={{ fontSize: '1rem' }} /> and{' '}
          </>
        )}
        <LeaseTermsTooltip styling={{ fontSize: '1rem' }} shortened={false} /> financing options mean that you don't
        have to put your important purchases on hold.{' '}
        {formType === FormTypes.Short && (
          <>
            Plus, checking your eligibility <strong>won't impact your credit score</strong>.
          </>
        )}
      </h6>
    );
  };

  return (
    <Suspense fallback={<PageLoader />}>
      <FormWrapper>
        <Title />
        <Subtitle />

        <Formik
          onSubmit={handleSubmit}
          initialValues={{ cellPhone: appCellPhone || orderCellPhone }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {() => (
            <Form>
              <Field
                name="cellPhone"
                id="cellPhone"
                generalErrorMessage={typeNameErrorMapper}
                type="tel"
                inputMode="numeric"
                label="Enter your mobile number"
                component={TextField}
                useRawValue={true}
                autoFocus
                cleaveOptions={{
                  blocks: [3, 3, 4],
                  delimiters: ['-'],
                }}
              />
              <p style={{ fontSize: '0.725rem', color: colors.darkMidGray }}>
                We'll send you a verification code via text message
              </p>
              <Button type="submit" data-testid="nextButton" dataLoading={isLoading}>
                Continue
              </Button>
              <p>
                By continuing, you agree to{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://apply.koalafi.com/electronic-communications?support=lto"
                  onClick={() => track('E-Sign Agreement Viewed')}
                >
                  Koalafi's E-Sign Agreement
                </a>
                {' & '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://koalafi.com/privacy-policy"
                  onClick={() => track('Privacy Policy Viewed')}
                >
                  Privacy Policy
                </a>{' '}
                and understand that you will be assessed for{' '}
                {appMode === AppMode.loan ? (
                  <>both our loan and lease-to-own financing options.</>
                ) : (
                  <>our lease-to-own financing option.</>
                )}
              </p>
              {!isVirtualCard && isLongForm && isBrickAndMortar && financingType === 'lto_only' && (
                <p>
                  <br />
                  Don't have your phone?{' '}
                  <a
                    target="_self"
                    rel="noopener noreferrer"
                    href={getSkipUnifiedUrl()}
                    onClick={() => track('Clicked link to apply without phone')}
                  >
                    Apply here
                  </a>
                </p>
              )}
            </Form>
          )}
        </Formik>
        {/* TODO: Add terms of service link (does not exist in long form) */}
      </FormWrapper>
    </Suspense>
  );
};

export default OTPRequest;
