import { createMachine, sendParent } from 'xstate';
import { EcommMachineEvents } from './types/ecommMachine.types';
import { NavigationSections } from '../constants';
import {
  EntryPointEvents,
  EntryPointMachineContext,
  EntryPointMachineEvents,
  EntryPointMachineStates,
  EntryPointStates,
} from './types/entrypointMachine.types';
import { transferToRapid } from '../helpers/cookies/unified.helper';

export default createMachine<EntryPointMachineContext, EntryPointMachineEvents, EntryPointMachineStates>(
  {
    id: 'EntryPoint',
    initial: EntryPointStates.Initial,
    // In future versions, there will be context that lives here instead of in redux
    context: {
      order: null,
      page: 'OTPVerification',
    },
    states: {
      [EntryPointStates.Initial]: {
        tags: 'Loading',
        always: [
          { target: EntryPointStates.OTPVerification, cond: 'OTPVerification' },
          { target: EntryPointStates.WelcomeBack, cond: 'WelcomeBack' },
          { target: EntryPointStates.BankAccountError, cond: 'BankAccountError' },
          { target: EntryPointStates.DebitCardError, cond: 'DebitCardError' },
          { target: EntryPointStates.LeaseDeclined, cond: 'LeaseDeclined' },
          { target: EntryPointStates.LoanDeclined, cond: 'LoanDeclined' },
          { target: EntryPointStates.ContractSummary, cond: 'ContractSummary' },
          { target: EntryPointStates.DebitCardError, cond: 'DebitCardError' },
          { target: EntryPointStates.ApprovalConfirmation, cond: 'ApprovalConfirmation' },
          { target: EntryPointStates.Done, cond: 'Done' },
          { target: EntryPointStates.DraftAppReviewAndSubmit, cond: 'DraftAppReviewAndSubmit' },
        ],
      },
      [EntryPointStates.OTPVerification]: {
        id: 'OTPVerification',
        initial: EntryPointStates.OTPRequest,
        states: {
          [EntryPointStates.OTPRequest]: {
            on: {
              [EntryPointEvents.NEXT]: {
                type: 'final',
                actions: sendParent((_context) => ({
                  type: EcommMachineEvents.CUSTOMER_LOOKUP_EVENT,
                })),
              },
            },
            meta: {
              navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
            },
          },
        },
      },
      [EntryPointStates.WelcomeBack]: {
        id: 'WelcomeBack',
        on: {
          [EntryPointEvents.NEXT]: {
            type: 'final',
            actions: sendParent((_context) => ({
              type: EcommMachineEvents.EXISTING_APPLICATION,
              data: { source: 'WelcomeBack.NEXT' },
            })),
          },
        },
        meta: {
          navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
        },
      },
      [EntryPointStates.ContractSummary]: {
        id: 'ContractSummary',
        on: {
          [EntryPointEvents.NEXT]: {
            type: 'final',
            actions: sendParent((_context) => ({
              type: EcommMachineEvents.EXISTING_APPLICATION,
              data: { source: 'ContractSummary.NEXT' },
            })),
          },
          [EntryPointEvents.BACK]: {
            type: 'final',
            actions: sendParent((_context) => ({
              type: EcommMachineEvents.EXISTING_APPLICATION,
              data: { source: 'ContractSummary.BACK' },
            })),
          },
          [EntryPointEvents.EDIT_DEBIT_INFO]: {
            type: 'final',
            actions: sendParent((_context) => ({
              type: EcommMachineEvents.EXISTING_APPLICATION,
              data: { source: 'ContractSummary.EDIT_DEBIT_INFO' },
            })),
          },
        },
        meta: {
          navigationSection: NavigationSections.REVIEW_AND_PAY,
        },
      },
      [EntryPointStates.BankAccountError]: {
        id: 'BankAccountError',
        on: {
          [EntryPointEvents.NEXT]: {
            type: 'final',
            actions: sendParent((_context) => ({
              type: EcommMachineEvents.EXISTING_APPLICATION,
              data: { source: 'BankAccountError.NEXT' },
            })),
          },
        },
        meta: {
          navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
        },
      },
      [EntryPointStates.DebitCardError]: {
        id: 'DebitCardError',
        on: {
          [EntryPointEvents.NEXT]: {
            type: 'final',
            actions: sendParent((_context) => ({
              type: EcommMachineEvents.EXISTING_APPLICATION,
              data: { source: 'DebitCardError.NEXT' },
            })),
          },
        },
        meta: {
          navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
        },
      },
      [EntryPointStates.ApprovalConfirmation]: {},
      [EntryPointStates.LoanDeclined]: {},
      [EntryPointStates.LeaseDeclined]: {},
      [EntryPointStates.Done]: {},
      [EntryPointStates.DraftAppReviewAndSubmit]: {
        entry: 'transferToRapid',
      },
    },
    schema: {
      context: {} as EntryPointMachineContext,
      events: {} as EntryPointMachineEvents,
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      transferToRapid: () => {
        transferToRapid();
      },
    },
    guards: {
      ApprovalConfirmation: (context: EntryPointMachineContext) => context.page === 'ApprovalConfirmation',
      WelcomeBack: (context: EntryPointMachineContext) => context.page === 'WelcomeBack',
      OTPVerification: (context: EntryPointMachineContext) => context.page === 'OTPVerification',
      ContractSummary: (context: EntryPointMachineContext) => context.page === 'ContractSummary',
      BankAccountError: (context: EntryPointMachineContext) => context.page === 'BankAccountError',
      LeaseDeclined: (context: EntryPointMachineContext) => context.page === 'LeaseDecline',
      LoanDeclined: (context: EntryPointMachineContext) => context.page === 'LoadDecline',
      DebitCardError: (context: EntryPointMachineContext) => context.page === 'DebitCardError',
      Done: (context: EntryPointMachineContext) => context.page === 'Done',
      DraftAppReviewAndSubmit: (context: EntryPointMachineContext) => context.page === 'DraftAppReviewAndSubmit',
    },
  },
);
