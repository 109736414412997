import { EventObject, Typestate } from 'xstate';
import { Order } from '../../gql/graphql';

// Context Types
export type DraftMachineContext = {
  order?: Order | null;
  transitionedFrom?: DraftStates | null;
  highestSupportedApplicationMilestone: number | undefined | null;
};

// State Types
export enum DraftStates {
  Entry = 'Entry',
  PersonalInformation = 'personalInformation',
  ContactInformation = 'contactInformation',
  BillingAddress = 'billingAddress',
  IncomeSource = 'incomeSource',
  BankingInformation = 'bankingInformation',
  DebitCardInformation = 'debitCardInformation',
  ReviewAndSubmit = 'reviewAndSubmit',
  AmendAndResubmit = 'amendAndResubmit',
  TransferToRapid = 'transferToRapid',

  // info pages
  CustomerIdProgram = 'CustomerIdProgram',
  TermsAndConditions = 'TermsAndConditions',
  PrequalificationNotice = 'PrequalificationNotice',
  AuthorizedUserTerms = 'AuthorizedUserTerms',
}

export interface DraftMachineStatesSchema {
  [DraftStates.Entry]: {};
  [DraftStates.PersonalInformation]: {};
  [DraftStates.ContactInformation]: {};
  [DraftStates.BillingAddress]: {};
  [DraftStates.IncomeSource]: {};
  [DraftStates.BankingInformation]: {};
  [DraftStates.DebitCardInformation]: {};
  [DraftStates.ReviewAndSubmit]: {};
  [DraftStates.AmendAndResubmit]: {};
  [DraftStates.TransferToRapid]: {};
  [DraftStates.CustomerIdProgram]: {};
  [DraftStates.TermsAndConditions]: {};
  [DraftStates.PrequalificationNotice]: {};
  [DraftStates.AuthorizedUserTerms]: {};
}

export interface DraftMachineStates extends Typestate<DraftMachineContext> {
  type: DraftMachineStatesSchema;
}

// Event Types
export enum DraftEvents {
  NEXT = 'NEXT',
  BACK = 'BACK',

  // info page events
  VIEW_CUSTOMER_ID_PROGRAM = 'ViewCustomerIdProgram',
  VIEW_TERMS_AND_CONDITIONS = 'ViewTermsAndConditions',
  VIEW_PREQUALIFICATION_NOTICE = 'ViewPrequalificationNotice',
  VIEW_AUTHORIZED_USER_TERMS = 'ViewAuthorizedUserTerms',
}

type DraftEventsTypeSchema =
  | DraftEvents.NEXT
  | DraftEvents.BACK
  | DraftEvents.VIEW_CUSTOMER_ID_PROGRAM
  | DraftEvents.VIEW_TERMS_AND_CONDITIONS
  | DraftEvents.VIEW_PREQUALIFICATION_NOTICE
  | DraftEvents.VIEW_AUTHORIZED_USER_TERMS;

export interface DraftMachineEvents extends EventObject {
  type: DraftEventsTypeSchema;
  data?: any;
}

export enum DraftGuardsType {
  LEASE_DECLINED = 'LeaseDecline',
  LOAN_DECLINED = 'LoanDecline',
  LTO_INELIGIBLE = 'LTOIneligible',
  APP_APPROVED = 'AppApproved',
  GENERAL_APPLY_ERRORS = 'GeneralApplyErrors',
  SOFT_DECLINE = 'SoftDecline',
}
type DraftGuardsTypeSchema =
  | DraftGuardsType.LEASE_DECLINED
  | DraftGuardsType.LOAN_DECLINED
  | DraftGuardsType.LTO_INELIGIBLE
  | DraftGuardsType.GENERAL_APPLY_ERRORS
  | DraftGuardsType.SOFT_DECLINE
  | DraftGuardsType.APP_APPROVED;

export interface DraftMachineGuards extends EventObject {
  type: DraftGuardsTypeSchema;
}
