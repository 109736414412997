import React from 'react';
import { useActor } from '@xstate/react';
import OTPRequest from '../otpRequest/OTPRequest';
import { pageContentConfig } from '../../components/config/pageContent';
import WelcomeBack from '../welcomeBack/WelcomeBack';
import { EntryPointEvents } from '../../state-machine/types/entrypointMachine.types';
import InternalError from '../internalError/InternalError';
import Loader from '../../components/loader/Loader';
import Declined from '../declined/Declined';
import ContractSummary from '../contractSummary/ContractSummary';
import DebitCardError from '../debitCardError/debitCardError';
import ErrorsFound from '../errorsFound/ErrorsFound';
import LeaseSigned from '../leaseSigned/LeaseSigned';
import ApprovalConfirmation from '../approvalConfimation/ApprovalConfirmation';
import LongLoader from '../../components/longLoader/LongLoader';

type FlowContainerProps = {
  parentMachine: any;
  setHeaderGoBackFunc: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
  setCurrentNode: (state: [string] | undefined) => void;
};

const EntryPointFlow = ({ parentMachine, setHeaderGoBackFunc, setCurrentNode }: FlowContainerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [parentState, parentTransition, parentInterpreter] = parentMachine;
  const [entryPointState, entryPointSend] = useActor<any, any>(parentInterpreter.children.get('EntryPointMachine'));

  switch (true) {
    case entryPointState.hasTag('Loading'):
      return <Loader source="entrypoint" />;
    case entryPointState.matches('OTPVerification'):
      return (
        <OTPRequest
          messages={{ ...pageContentConfig[entryPointState.context.order!.dealer?.financingType]['otpRequest'] }}
          onNext={() => entryPointSend({ type: EntryPointEvents.NEXT })}
        />
      );
    case entryPointState.matches('WelcomeBack'):
      return (
        <WelcomeBack
          onNext={() => entryPointSend({ type: EntryPointEvents.NEXT })}
          ecommApprovalOnly={entryPointState.context.ecommApprovalOnly}
        />
      );
    case entryPointState.matches('ContractSummary'):
      return (
        <ContractSummary
          onNext={() => entryPointSend({ type: EntryPointEvents.NEXT })}
          onBack={() => entryPointSend({ type: EntryPointEvents.BACK })}
          onDebitEdit={() => entryPointSend({ type: EntryPointEvents.EDIT_DEBIT_INFO })}
        />
      );
    case entryPointState.matches('BankAccountError'):
      return (
        <ErrorsFound
          body={`We're unable to authorize your bank account as submitted. Please review for errors and resubmit.`}
          buttonText="Update bank account"
          buttonBehavior={() => entryPointSend({ type: EntryPointEvents.NEXT })}
        />
      );
    case entryPointState.matches('DebitCardError'):
      return <DebitCardError onNext={() => entryPointSend({ type: EntryPointEvents.NEXT })} />;
    case entryPointState.matches('LeaseDeclined'):
      return (
        <Declined
          body={`We couldn't offer you financing options at this time.`}
          trackDetails={{ title: 'Application Declined' }}
          additionalBody={`You'll soon receive a letter with more information on why your application didn't fit our criteria. You will be able to apply again in 30 days.`}
        />
      );
    case entryPointState.matches('LoanDeclined'):
      return (
        <Declined
          body={`We are unable to pre-qualify you at this time.`}
          trackDetails={{ title: 'Loan Denied' }}
          additionalBody={`Thank you for considering Koalafi. Unfortunately we are unable to pre-qualify you for our financing options at this time. 
      
                You may receive a separate notice with more information.`}
        />
      );
    case entryPointState.matches('ApprovalConfirmation'):
      return <ApprovalConfirmation ecommApprovalOnly={entryPointState.context.ecommApprovalOnly} />;
    case entryPointState.matches('Done'):
      return <LeaseSigned />;
    case entryPointState.matches('DraftAppReviewAndSubmit'):
      return <LongLoader message="We're taking you to your application" />;
    default:
      return <InternalError></InternalError>;
  }
};

export default EntryPointFlow;
